.box-merchant-container {
  border-radius: 15px;
  min-height: 290px;
  //   height: 100%;
  position: relative;
  box-shadow: 0px 3px 15px 1px #dedede;

  .edit-merchant-container {
    text-align: right;
    padding: 4px 8px 0px 0px;

    .edit-merchant-icon {
      cursor: pointer;
      color: rgb(119, 119, 119);

      &:hover {
        color: green;
      }
    }
  }

  .merchant-logo-container {
    text-align: center;
    height: 8.5rem;

    .merchant-logo {
      width: 8.5rem;
      height: 8.5rem;
      border-radius: 10px;
    }
  }

  .merchant-name-container {
    font-weight: bold;
    text-align: center;
    font-size: 1.2rem;
    color: rgb(0, 0, 0);
    width: 100%;
    padding: 10px 10px 0px 10px;
  }

  .dashboard-button-container {
    padding: 15px 10px;
    position: absolute;
    bottom: 0;
    width: 100%;

    .dashboard-button {
      border: 1px solid #009b00 !important;
      border-radius: 10px !important;
      background-color: #009b00;
      color: white !important;
      width: 100% !important;
      height: 35px !important;
      font-size: 14px !important;

      &:hover {
        border: 2px solid #008100 !important;
        background-color: #008100 !important;
        color: white !important;
      }
    }
  }
}
