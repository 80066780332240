.cancel-button {
  border: 2px solid !important;
  border-radius: 20px !important;
  background-color: rgb(253, 0, 0) !important;
  color: white !important;
  width: 110px !important;
  height: 35px !important;
  font-size: 14px !important;
  margin-right: 0.3125rem;
  line-height: 100% !important;

  &:hover {
    border: 2px solid rgb(221, 0, 0) !important;
    background-color: rgb(221, 0, 0) !important;
    color: white !important;
  }
}
