.create-order-item-container {
    height: 350px;
    overflow: auto;
    padding: 5px 10px;
    -ms-overflow-style: none !important;
}

.item-box-product {
    box-shadow: 0px 3px 10px #dfdfdf;
    //   border: 1px solid black;
    margin-bottom: 10px;
    border-radius: 10px;
    padding: 5px;
    // color: grey;

    .product-image-container {
        padding: 5px;
    }

    .product-detail-container {
        padding: 5px 10px;
        color: grey
    }

    .product-action-container {
        padding: 5px;
        color: rgb(107, 107, 107);
    }
}

.create-order-item-container::-webkit-scrollbar {
    display: none;
  }