.detail-topup-title {
  color: rgb(0, 0, 0);
  font-weight: bold;
  font-size: 26px;
  padding-bottom: 20px;
}

.detail-topup-section {
  padding: 20px 0px;
}

.top-backpage-section {
  padding: 20px 0px;
}
