.btn-create-category {
  background-color: #009b00 !important;
  width: 100%;
  color: white !important;
  border-radius: 7px !important;
  margin-bottom: 12px;
  height: 40px !important;
  line-height: 100% !important;
}

.form-input-category {
  border-radius: 5px !important;

  &:hover {
    border: 1px solid #009b00 !important;
  }
}
