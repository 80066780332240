.detail-title-container {
  padding: 20px 0px;

  .detail-section-title {
    font-size: 22px;
    font-weight: bold;
  }

  .detail-section-line {
    width: 100%;
    border: 1px solid rgb(214, 214, 214);
  }
}
