.venue-photo {
  width: 160px;
  border-radius: 10px;
  box-shadow: 0px 5px 10px 1px #adadad;
}

.custom-table-head-section {
  padding-bottom: 4px;

  .custom-table-head-container {
    padding: 8px 10px;
    border: 1px solid black;
    border-radius: 5px;
    background-color: rgb(239, 255, 239);
  }
}

.custom-table-body-section {
  padding-bottom: 4px;

  .custom-table-body-container {
    padding: 3px 10px;
    border: 1px solid rgb(146, 146, 146);
    border-radius: 5px;
    color: rgb(153, 153, 153);

    &:hover {
      background-color: rgb(233, 255, 233);
    }
  }
}
