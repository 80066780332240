.pagelist-container {
  height: 100%;
  overflow: auto;

  .pagelist-top-container {
    height: 10%;

    .left-top {
      padding: 10px 0px;
    }

    .right-top {
      padding: 10px 0px;
    }
  }

  .pagelist-content-container {
    height: 100%;
  }
}

.pagelist-container::-webkit-scrollbar {
  display: none !important;
}
