.form-service-container {
  .left-section-form-service {
    padding-right: 20px;

    .container-upload-photo-venue {
      margin-right: 10px;
      margin-bottom: 5px;

      .upload-photo {
        text-align: center;
        border: 1px dashed rgb(156, 156, 156);
        width: 120px;
        height: 100px;
        cursor: pointer;
        border-radius: 15px;
        color: rgb(204, 204, 204);

        &:hover {
          border: 2px dashed #009b00;
          // color: #009b00;
        }

        .container-loading-upload-photo-venue {
          padding-top: 25px;

          .loading-icon-upload-photo-venue {
            font-size: 40;
            color: #009b00;
          }
        }

        .container-icon-photo-venue {
          position: absolute;
          top: 8%;
          width: 100%;
        }

        .venue-photo-preview {
          position: absolute;
          top: 15%;
          left: 0;
          width: 100%;
          height: 65px;
        }
      }
    }

    .add-photo {
      text-align: center;
      border: 1px dashed rgb(156, 156, 156);
      width: 120px;
      height: 100px;
      cursor: pointer;
      border-radius: 15px;
      color: rgb(230, 230, 230);

      &:hover {
        border: 2px dashed #009b00;
        color: rgb(204, 204, 204);
      }

      .container-icon-add-photo {
        position: absolute;
        top: 20%;
        width: 100%;
      }
    }
  }

  .right-section-form-service {
    border-left: 1px dashed rgb(211, 211, 211);
    padding-left: 20px;
  }

  .form-input {
    border-radius: 7px !important;

    &:hover {
      border: 1px solid #009b00 !important;
    }
  }

  .ant-select-selector {
    border-radius: 7px !important;
  }

  .ant-form-item {
    margin-bottom: 10px !important;
  }
}

.button-cancel-form-venue {
  background-color: white !important;
  border: 1px solid red !important;
  color: red !important;
  border-radius: 15px !important;
  width: 100px;

  &:hover {
    border: 2px solid red !important;
    background-color: red !important;
    color: white !important;
  }
}

.button-save-form-venue {
  background-color: #009b00 !important;
  border: 2px solid #009b00 !important;
  color: white !important;
  border-radius: 15px !important;
  width: 100px;
  margin-right: 10px;

  &:hover {
    border: 2px solid #008100 !important;
    background-color: #008100 !important;
    color: white !important;
  }
}

.ant-select-selector {
  &:hover {
    border: 1px solid #009b00 !important;
  }
}
