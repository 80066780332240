.item-detail-container {
  padding: 3px 0px;

  .label-container {
    padding: 3px 0px;
    color: rgb(0, 0, 0);
    font-size: 15px;
  }

  .value-container {
    border: 1px solid rgb(0, 0, 0);
    // height: 30px;
    padding: 3px 10px;
    border-radius: 5px;
    color: rgb(122, 122, 122);
  }
}
