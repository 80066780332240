.ant-layout-content {
  //   padding: 15px !important;
  height: 100vh !important;
  position: relative !important;

  .page-container {
    height: calc(100% - 90px);
    position: absolute !important;
    bottom: 0 !important;
    width: 100% !important;
    padding: 0px 0.9375rem 0.9375rem 0.9375rem;

    .page {
      height: 100%;
      background-color: white !important;
      border-radius: 0.9375rem !important;
      padding: 0.9375rem 1.25rem;
    }
  }
}
