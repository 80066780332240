.box-create-merchant-container {
  border-radius: 15px;
  min-height: 220px;
  position: relative;
  box-shadow: 0px 3px 15px 1px #dedede;
  cursor: pointer;
  color: rgb(190, 190, 190);

  &:hover {
    color: rgb(141, 141, 141);
  }
}

.pagination-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 60px 20px;
  text-align: right;
}
