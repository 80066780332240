.beranda-container {
  padding: 20px 0px;
  height: 100%;
  overflow: auto;

  ::-webkit-scrollbar {
    display: hide;
  }

  .rangepicker-container {
    padding-bottom: 7px;

    .ant-picker.home-monthpicker {
      border: 1px solid #dedede !important;
      border-radius: 10px !important;
    }
  }

  .chart-section {
    .chart-rangepicker-container {
      padding-bottom: 5px;

      .home-rangepicker.ant-picker.ant-picker-range {
        border: 1px solid #dedede !important;
        border-radius: 10px !important;
        // box-shadow: 0px 5px 15px 1px #dedede !important;
      }
    }

    .chartbar-container {
      box-shadow: 0px 3px 10px 1px #dedede;
      border-radius: 15px;
      padding: 10px;
      width: 458px;
      height: 300px;
    }
  }
}

.beranda-container::-webkit-scrollbar {
  display: none;
}
