.ant-menu-item {
  &:hover {
    color: #009b00 !important;
  }
}

.ant-menu-item-selected {
  background-color: #c9f7c9 !important;
  color: #009b00 !important;
  // border-radius: 20px !important;

  .menu-link {
    color: #009b00 !important;
  }
}

.ant-menu-inline .ant-menu-item::after {
  border-right: 0px !important;
}

.ant-menu-submenu-title:hover {
  color: #009b00 !important;
}

.ant-menu-submenu-selected {
  color: #c9f7c9 !important;
}

.menu-link:hover {
  color: #009b00 !important;
}

.menu-link::before {
  color: #c9f7c9 !important;
}
