.ant-table-row {
  color: rgb(112, 112, 112) !important;

  &:hover {
    .ant-table-cell {
      background-color: rgb(238, 255, 238) !important;
    }
  }
}

.ant-pagination-item {
  border-radius: 7px !important;
}

.ant-pagination-item-link {
  border-radius: 7px !important;
}

.ant-pagination-next {
  .ant-pagination-item-link:hover {
    border-radius: 7px !important;
    border: 2px solid rgb(4, 163, 4) !important;
    color: rgb(4, 163, 4) !important;
  }
}

.ant-pagination-item:hover {
  border: 2px solid rgb(0, 128, 0) !important;
  border-radius: 7px !important;
  background-color: rgb(0, 128, 0) !important;

  a {
    color: white !important;
  }
}

.ant-pagination-item-active {
  border: 2px solid rgb(4, 163, 4) !important;
  border-radius: 7px !important;
  background-color: rgb(4, 163, 4) !important;

  a {
    color: white !important;
  }
}
