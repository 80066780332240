.loading-container {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1000;
  background-color: #ffffff;
  opacity: 0.7;
}

.ant-spin-dot-item {
  background-color: green !important;
  font-size: 18px !important;
}
