.form-edit-merchant-hobby {
  padding-top: 5px;

  .upload-photo {
    text-align: center;
    border: 1px dashed rgb(156, 156, 156);
    width: 120px;
    height: 100px;
    cursor: pointer;
    border-radius: 15px;
    color: rgb(204, 204, 204);
  }

  .left-section {
    padding-bottom: 20px;
  }

  .row-edit-hobby {
    padding-bottom: 10px;

    .ant-checkbox-inner {
      background-color: rgb(16, 155, 16) !important;
      border: 1px solid rgb(16, 155, 16) !important;
    }
  }

  .form-input {
    border-radius: 7px !important;
  }

  .ant-form-item {
    margin-bottom: 10px !important;
  }

  .right-section {
    border-left: 1px dashed rgb(211, 211, 211);
    padding-left: 20px;

    .button-modal-edit-hobby {
      background-color: #009b00;
      border: 2px solid #009b00;
      color: white;
      border-radius: 10px;
      // width: 70px;

      &:hover {
        border: 2px solid #008100;
        background-color: #008100;
        color: white;
      }
    }
  }

  .button-cancel-edit {
    background-color: white;
    border: 1px solid red;
    color: red;
    border-radius: 15px;

    &:hover {
      border: 1px solid red;
      background-color: red;
      color: white;
    }
  }

  .button-save-edit {
    background-color: #009b00;
    border: 1px solid #009b00;
    color: white;
    border-radius: 15px;
    margin-right: 5px;

    &:hover {
      border: 1px solid #009b00;
      background-color: #008100;
      color: white;
    }
  }
}

.form-input {
  border-radius: 7px !important;

  &:hover {
    border: 1px solid #009b00 !important;
  }
}

.back-button-modal-bank {
  background-color: red !important;
  color: white !important;
  border: 2px solid red !important;
  border-radius: 10px !important;
}

.ant-picker.ant-picker-range {
  border-radius: 8px !important;
}

.cancel-button-operationhours {
  border: 1px solid rgb(255, 49, 49) !important;
  color: rgb(255, 49, 49) !important;
  border-radius: 15px !important;
  margin-right: 5px !important;

  &:hover {
    background-color: red !important;
    color: white !important;
  }
}

.save-button-operationhours {
  background-color: #009b00 !important;
  color: white !important;
  border: 2px solid #009b00 !important;
  border-radius: 15px !important;
  color: rgb(179, 179, 179);
}

// OperationalHourHobby
.table-head-operation-hour {
  padding-bottom: 6px;

  .table-head-container {
    // border: 2px solid black;
    box-shadow: 0px 3px 8px #dfdfdf;
    padding: 10px 5px;
    border-radius: 10px;
  }
}

.table-body-data {
  // border: 2px solid rgb(194, 194, 194);
  box-shadow: 0px 3px 8px #dfdfdf;
  border-radius: 10px;
  padding: 8px 5px;
  margin-bottom: 6px;
}

.ant-picker-range {
  &:hover {
    border: 1px solid #009b00 !important;
  }
}

.ant-checkbox-input {
  &:hover {
    border: 1px solid #009b00 !important;
  }
}

.ant-checkbox-checked {
  .ant-checkbox-inner {
    background-color: #009b00 !important;
    border: 1px solid #009b00 !important;
  }
}
