$green-mo: #009b00;

.login-container {
  height: 100vh;

  .company-logo {
    width: 4.3rem;
  }

  .form-container {
    margin-top: 1.875rem;

    .ant-input-affix-wrapper {
      border-radius: 3.125rem !important;
    }

    .ant-input-affix-wrapper:hover {
      border: 1px solid $green-mo !important;
    }

    .login-button.ant-btn {
      background-color: $green-mo !important;
      border: 1px solid $green-mo !important;
    }

    .login-button.ant-btn:hover {
      background-color: #45a545 !important;
      font-weight: bold !important;
    }

    .ant-form-item-explain {
      padding-bottom: 10px !important;
    }
  }

  .footer-container {
    margin-top: 9.375rem;

    .footer-paragraph {
      text-align: center;
      font-size: 13px;
      color: rgb(153, 153, 153);
    }
  }
}
