.left-section {
  padding-bottom: 20px;
}

.upload-photo {
  text-align: center;
  border: 1px dashed rgb(156, 156, 156);
  width: 120px;
  height: 100px;
  cursor: pointer;
  border-radius: 15px;
  color: rgb(204, 204, 204);
}

.button-save-edit {
  background-color: #009b00 !important;
  border: 1px solid #009b00 !important;
  color: white !important;
  border-radius: 8px !important;
  margin-right: 5px;

  &:hover {
    border: 1px solid #009b00 !important;
    background-color: #008100 !important;
    color: white !important;
  }
}

.right-section {
  border-left: 1px dashed rgb(211, 211, 211);
  padding-left: 20px;

  .button-modal-edit-hobby {
    background-color: #009b00;
    border: 2px solid #009b00;
    color: white;
    border-radius: 10px;
    // width: 70px;

    &:hover {
      border: 2px solid #008100;
      background-color: #008100;
      color: white;
    }
  }
}

.row-edit-hobby {
  padding-bottom: 10px;
}
