.edit-order-item-container {
  height: 500px;
  overflow: auto;
  padding: 5px 10px;
  -ms-overflow-style: none !important;

  .item-box-product {
    box-shadow: 0px 3px 10px #dfdfdf;
    //   border: 1px solid black;
    margin-bottom: 10px;
    border-radius: 10px;
    padding: 5px;

    .product-image-container {
      padding: 5px;
    }

    .product-detail-container {
      padding: 5px 10px;

      .product-name {
        font-weight: bold;
        color: black;
        font-size: 15px;
      }

      .product-description {
        color: grey;
        font-size: 13px;
      }
    }
  }
}

.delete-item:hover {
  color: red
}

.edit-order-item-container::-webkit-scrollbar {
  display: none;
}
